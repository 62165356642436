.icon_btn:hover {
  @apply !border-[#28566E];
}

.icon_btn:focus {
  @apply border-icon-on-primary bg-interactive-action-hovered;
}

.icon_btn:active {
  @apply !border-interactive-secondary-default !bg-interactive-secondary-default;
}

.icon_btn:focus-visible {
  outline-color: transparent;
  outline-offset: unset;
}

.expat_btn [class~="btnCompLibrary_rightIcon"] {
  margin-left: 4px;
}

.back_button:focus {
  @apply border-icon-on-primary bg-transparent;
}
